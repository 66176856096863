
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  name: 'assessment-list',
  mixins: [apiEndpoint],
  components: {
    Datatable,
  },
  data() {
    return {
      trainee: {
        entity_id: '',
        training_institute_id: '',
        course_info_id: '',
        tranche_id: '',
        batch_info_id: '',
        term_no: '',
      },
      api_url: '',
      moment: '' as any,
      assessmentList: [],
      assessmentCount: [],
      tranches: [],
      associations: [],
      institutes: [],
      courses: [],
      courseInfo: [],
      batches: [],
      assessors: [],
      termList: [],
      textAssessment: '',
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showAssessmentList: false,
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Reference No.',
          key: 'ref_no',
          sortable: true,
        },
        {
          name: ' Registration No.',
          key: 'reg_no',
          sortable: true,
        },
        {
          name: 'Trainee Name	',
          key: 'name',
          sortable: true,
        },

        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Assessment Date',
          key: 'assessment_date',
          sortable: true,
        },
        {
          name: 'Reassessment Date',
          key: 'reassessment_date',
          sortable: true,
        },
        {
          name: 'Assessor',
          key: 'assessor',
          sortable: true,
        },
        {
          name: 'Assessment Result',
          key: 'assessment_result',
          sortable: true,
        },
      ],
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.associationList();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('term_no', this.trainee.term_no);

      let data = `${this.VUE_APP_API_URL}/api/assessment/assesment_list_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('term_no', this.trainee.term_no);

      await ApiService.post('assessment/assesment_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('term_no', this.trainee.term_no);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/assessment/assesment_list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Trainee Assessment Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
          this.trainee.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      this.load = true;
      await ApiService.get(
        'course/list?entity_id=' +
          this.trainee.entity_id +
          '&tranche=' +
          this.trainee.tranche_id +
          '&institute_info_id=' +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
      this.load = true;
      await ApiService.get(
        'batch/list?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&institute_info_id=' +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessmentEligibleTerm() {
      this.trainee.term_no = '';
      this.load = true;
      await ApiService.get('assessment/eligible-term?batch_info_id=' +
          this.trainee.batch_info_id
      )
        .then((response) => {
          this.load = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async getAssessmentCount() {
      await ApiService.get(
        'assessment/count?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&tranche_id=' +
          this.trainee.tranche_id +
          "&term_no=" +
          this.trainee.term_no
      )
        .then((response) => {
          this.assessmentCount = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async checkAssessmentEligiblity() {
      this.load = true;
      if (
        this.trainee.entity_id &&
        this.trainee.course_info_id &&
        this.trainee.training_institute_id &&
        this.trainee.batch_info_id &&
        this.trainee.tranche_id
      ) {
        this.getAssessmentList(); 
      } else {
        this.load = false;
        Swal.fire({
          title: 'Error!',
          html: 'Please Select All Required Field!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async getAssessmentList() {
      this.getAssessmentCount();
      let url  = 'assessment/list';
      if(this.trainee.term_no && this.termList.length != Number(this.trainee.term_no)){
        url = 'assessment/list-term-wise';
      }
      await ApiService.get(
          url+'?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&tranche_id=' +
          this.trainee.tranche_id+
          "&term_no=" +
          this.trainee.term_no
      )
        .then((response) => {
          this.assessmentList = response.data.data;
          this.showAssessmentList = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
